import React, { Component } from 'react'
import { Link } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, toast, ToastContainer } from 'mdbreact'
import { Zoom } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name muss mindestens 2 Zeichen lang sein')
    .required('Bitte Vollständiger Name angeben'),
  email: Yup.string()
    .email('Feld wurde nicht im erwarteten Format ausgefüllt')
    .required('Bitte E-Mail-Adresse angeben'),
  company: Yup.string()
    .min(2, 'Firmenname muss mindestens 2 Zeichen lang sein')
    .required('Bitte Firmenname angeben'),
  zip: Yup.string()
    .min(2, 'Postleitzahl muss mindestens 2 Zeichen lang sein')
    .required('Bitte Postleitzahl angeben'),
  country: Yup.string()
    .min(2, 'Land muss mindestens 2 Zeichen lang sein')
    .required('Bitte Land angeben'),
  product: Yup.string()
    .min(2, 'Produkt muss mindestens 2 Zeichen lang sein')
    .required('Bitte Produkt angeben'),
  productversion: Yup.string()
    .min(2, 'Produkt-Version muss mindestens 2 Zeichen lang sein')
    .required('Bitte Produkt-Version angeben'),
  os: Yup.string()
    .min(2, 'Betriebssystem muss mindestens 2 Zeichen lang sein')
    .required('Bitte Betriebssystem angeben'),
  osversion: Yup.string()
    .min(2, 'Betriebssystem-Version muss mindestens 2 Zeichen lang sein')
    .required('Bitte Betriebssystem-Version angeben'),    
  consentsubscribe: Yup.boolean().oneOf([true], 'Ihre Zustimmung ist erforderlich'),
  consentstore: Yup.boolean().oneOf([true], 'Ihre Zustimmung ist erforderlich'),  
})

const formUrl = 'https://script.google.com/macros/s/AKfycbw8sWhz2sHFe4TTAmlS1Uz3kknslsoBXjeRuB3THTHanlzQYkBbcF7Z0sVX6Mo77PzA/exec'

const messageConfirm = () => {
  return toast.info("Danke, dass Sie uns kontaktiert haben. Wir werden uns in Kürze bei Ihnen melden!", { closeButton: false, position: toast.POSITION.TOP_CENTER, transition: Zoom })
}

class ContactMain extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      pageUrl: null, 
      load: false,
      expired: 'false',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ 
        load: true, 
        pageUrl: location.href 
      })
    })
  }

  handleChange = value => {
    this.setState({ value })
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol md="6" className="md-0 mb-5">
            <Formik
              initialValues={{
                name: '',
                email: '',
                company: '',
                zip: '',
                country: '',             
                phone: '',
                product: '',
                productversion: '',
                os: '',
                osversion: '',
                deliverycompany: '',
                deliveryname: '',
                deliverycity: '',
                deliverystate: '',
                deliveryzip: '',
                deliverycountry: '',
                message: '',
                consentsubscribe: false,
                consentstore: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, {setSubmitting, resetForm}) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  Name: values.name,
                  Email: values.email,
                  Company: values.company,
                  Zip: values.zip,
                  Country: values.country,                  
                  Phone: values.phone,
                  Product: values.product,
                  ProductVersion: values.productversion,
                  OS: values.os,
                  OSVersion: values.osversion,
                  DeliveryCompany: values.deliverycompany,
                  DeliveryName: values.deliveryname,
                  DeliveryCity: values.deliverycity,
                  DeliveryState: values.deliverystate,
                  DeliveryZip: values.deliveryzip,
                  DeliveryCountry: values.deliverycountry,
                  Message: values.message,
                  Consentsubscribe: values.consentsubscribe,
                  Consentstore: values.consentstore,
                }
                try {
                  const response = axios({
                      method: 'get',
                      url: `${formUrl}?timestamp=&url=${encodeURIComponent(this.state.pageUrl)}&name=${encodeURIComponent(values.name)}&email=${encodeURIComponent(values.email)}&company=${encodeURIComponent(values.company)}&zip=${encodeURIComponent(values.zip)}&country=${encodeURIComponent(values.country)}&phone=${encodeURIComponent(values.phone)}&product=${encodeURIComponent(values.product)}&productversion=${encodeURIComponent(values.productversion)}&os=${encodeURIComponent(values.os)}&osversion=${encodeURIComponent(values.osversion)}&deliverycompany=${encodeURIComponent(values.deliverycompany)}&deliveryname=${encodeURIComponent(values.deliveryname)}&deliverycity=${encodeURIComponent(values.deliverycity)}&deliverystate=${encodeURIComponent(values.deliverystate)}&deliveryzip=${encodeURIComponent(values.deliveryzip)}&deliverycountry=${encodeURIComponent(values.deliverycountry)}&message=${encodeURIComponent(values.message)}&subscribe=${encodeURIComponent(values.consentsubscribe)}&store=${encodeURIComponent(values.consentstore)}`
                    })
                      //console.log(response)
                      this.setState({values: '', expired: 'true'})
                      resetForm()
                      messageConfirm()
                    } catch (e) {
                      console.log(`Axios request failed: ${e}`)
                    }
                  setSubmitting(false)
                }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form name="contact" method="post" action="" data-netlify="true" data-netlify-honeypot="bot-field" >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />
                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="name"> Name <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="name "name="name" aria-label="name" value={values.name} placeholder="Vollständiger Name" className={`form-control ${ touched.name && errors.name ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="name" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="email"> E-Mail-Adresse <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="email" name="email" aria-label="email" value={values.email} placeholder="E-Mail-Adresse" className={`form-control ${ touched.email && errors.email ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="email" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="company"> Firmenname <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="company" name="company" aria-label="company" value={values.company} placeholder="Firmenname" className={`form-control ${ touched.company && errors.company ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="company" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="zip"> Postleitzahl <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="zip" name="zip" aria-label="text" value={values.zip} placeholder="Postleitzahl" className={`form-control ${ touched.zip && errors.zip ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="zip" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="country"> Land <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="country" name="country" aria-label="country" value={values.country} placeholder="Land" className={`form-control ${ touched.country && errors.country ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="country" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="phone"> Telefon </label>
                        <Field type="tel" id="phone" name="phone" aria-label="phone" value={values.phone} placeholder="Telefon" className={`form-control ${ touched.phone && errors.phone ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="phone" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>
                
                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="product"> Produkt <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="product" name="product" aria-label="product" value={values.product} placeholder="Benötigte Softwareaktualisierung für welches Produkt?" className={`form-control ${ touched.product && errors.product ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="product" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="productversion"> Ihre aktuelle Version und Ihr aktueller Level, bitte 5-stellig <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="productversion" name="productversion" aria-label="productversion" value={values.productversion} placeholder="Ihre aktuelle Produkt-Version und Ihr aktueller Level, bitte 5-stellig" className={`form-control ${ touched.productversion && errors.productversion ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="productversion" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>               

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="os"> Betriebssystem <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="os" name="os" aria-label="os" value={values.os} placeholder="Eingesetztes Betriebssystem" className={`form-control ${ touched.os && errors.os ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="os" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="osversion"> Betriebssystem-Version <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="osversion" name="osversion" aria-label="osversion" value={values.osversion} placeholder="Betriebssystem-Version" className={`form-control ${ touched.osversion && errors.osversion ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="osversion" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>     

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="deliverycompany"> Firma für Zustellung </label>
                        <Field type="text" id="deliverycompany" name="deliverycompany" aria-label="deliverycompany" value={values.deliverycompany} placeholder="Firma, für das diese Softwareaktualisierung bestimmt ist" className={`form-control ${ touched.deliverycompany && errors.deliverycompany ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="deliverycompany" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow> 

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="deliveryname"> Name des Kontakts für Zustellung (falls abweichend) </label>
                        <Field type="text" id="deliveryname" name="deliveryname" aria-label="deliveryname" value={values.deliveryname} placeholder="Name des Kontakts für die Zustellung (wenn von oben abweichend)" className={`form-control ${ touched.deliveryname && errors.deliveryname ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="deliveryname" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="deliverycity"> Stadt </label>
                        <Field type="text" id="deliverycity" name="deliverycity" aria-label="deliverycity" value={values.deliverycity} placeholder="Stadt der Zustellung" className={`form-control ${ touched.deliverycity && errors.deliverycity ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="deliverycity" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="deliverystate"> Staat/Bezirk </label>
                        <Field type="text" id="deliverystate" name="deliverystate" aria-label="deliverystate" value={values.deliverystate} placeholder="Staat/Bezirk der Zustellung" className={`form-control ${ touched.deliverystate && errors.deliverystate ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="deliverystate" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>         

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="deliveryzip"> Postleitzahl </label>
                        <Field type="text" id="deliveryzip" name="deliveryzip" aria-label="deliveryzip" value={values.deliveryzip} placeholder="Postleitzahl der Zustellung" className={`form-control ${ touched.deliveryzip && errors.deliveryzip ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="deliveryzip" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="deliverycountry"> Land </label>
                        <Field type="text" id="deliverycountry" name="deliverycountry" aria-label="deliverycountry" value={values.deliverycountry} placeholder="Land der Zustellung" className={`form-control ${ touched.deliverycountry && errors.deliverycountry ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="deliverycountry" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>                                      

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="message">Kommentare/Mitteilungen</label>
                        <Field type="textarea" component="textarea" name="message" aria-label="message" value={values.message} placeholder="Mitteilung eingeben" className={`form-control ${ touched.message && errors.message ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="message" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                        Hiermit willigen Sie ein, dass die iET Solutions, LLC. Ihre im Formular angegebene E-Mail-Adresse verarbeiten darf, um im Rahmen eines Newsletter über Produkte, Dienstleistungen, Neuigkeiten und Veranstaltungen von iET Solutions zu informieren. Die Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit a) DSGVO.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mb-4 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="consentsubscribe"
                          name="consentsubscribe"
                          aria-label="consentsubscribe"
                          className={`form-check-input ${ touched.consentsubscribe && errors.consentsubscribe ? 'is-invalid' : '' }`}
                        />
                        <label htmlFor="consentsubscribe">
                          Ja bitte, ich möchte gern Mitteilungen von iET Solutions erhalten. <span style={{ color: 'red' }}>*</span>
                        </label>
                        <ErrorMessage name="consentsubscribe" component="div" className="invalid-feedback font-w-700 text-medium-small" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-400 text-small">
                        Hiermit willigen Sie ein, dass die iET Solutions, LLC. Ihre im Rahmen dieses Formulars angegebenen Daten auf ihren Servern und IT-Systemen speichert und zur Beantwortung Ihrer Anfrage sowie zur Bereitstellung des Software-Updates verarbeitet. Die Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit. a) DSGVO.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mb-2 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="consentstore"
                          name="consentstore"
                          aria-label="consentstore"
                          className={`form-check-input ${ touched.consentstore && errors.consentstore ? 'is-invalid' : '' }`}
                        />
                        <label htmlFor="consentstore">
                          Ja, ich willige ein. <span style={{ color: 'red' }}>*</span>
                        </label>
                        <ErrorMessage name="consentstore" component="div" className="invalid-feedback font-w-700 text-medium-small" />
                      </div>
                    </MDBCol>
                  </MDBRow>                  

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-400 text-small">
                        Ihre hier erteilte Einwilligung ist freiwillig und kann jederzeit und kostenlos für die Zukunft widerrufen werden. Weitere Informationen zur Speicherung und Verwendung Ihrer personenbezogenen Daten sowie Ihren ausführlichen Rechten und Widerrufsmöglichkeiten finden Sie in unseren <Link to="/datenschutzerklaerung/" className="effect">Hinweis zum Datenschutz</Link>.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12" className="mb-4">
                      <div style={{ paddingTop: '1rem' }}>
                        <button
                          type="submit"
                          className="mt-4 btn btn-medium btn-unicom"
                          style={{ display: 'block' }}
                          disabled={ isSubmitting ? true : false }
                        >
                          {isSubmitting ? 'Please wait...' : 'Absenden'}
                        </button>
                        <ToastContainer
                          transition={Zoom}
                          hideProgressBar={true}
                          newestOnTop={true}
                          autoClose={5000}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBCol>

     
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default ContactMain
